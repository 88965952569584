<template>
    <v-container fluid class="pt-0 mt-0">
        <HeaderAzul :titulo="curso.curso.curso" :subtitulo="curso.turma"/>
        <v-row>
            <v-col cols="12" md="6" lg="3"><v-card to="/curso/aula_atual" color="#FFD600"><v-card-text class="pt-3 my-0"><h2>Iniciar aula</h2></v-card-text></v-card></v-col>
            <template v-if="curso.escola.exibir_material === false">
                <v-col cols="12" md="6" lg="3">
                    <v-card>
                        <v-alert type="error">
                            Não identificamos o pagamento referente ao material solicitado. Por favor, entre em contato com seu professor ou a equipe responsável da escola para resolver esta situação. Agradecemos pela sua colaboração.
                        </v-alert>
                    </v-card>
                </v-col>
            </template>
            <template v-else>
                <v-col cols="12" md="6" lg="3"><v-card :to="`/learning_map/${this.id_curso}/${this.id_turma}`"><v-card-text class="pt-3 my-0"><h2>Learning Map</h2></v-card-text></v-card></v-col>
                <v-col cols="12" md="6" lg="3"><v-card :to="`/curso/aulas/${this.id_curso}/${this.id_turma}`"><v-card-text class="pt-3 my-0"><h2>Aulas</h2></v-card-text></v-card></v-col>
                <v-col cols="12" md="6" lg="3"><v-card :to="`/curso/material/${this.id_curso}/${this.id_turma}`"><v-card-text class="pt-3 my-0"><h2>Material</h2></v-card-text></v-card></v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>

import {mapState} from "vuex"
import HeaderAzul from "@/Views/Estrutura/HeaderAzul";
import axios from "axios";

export default {
    name: "CursoCompleto",
    props: ['id_curso', 'id_turma'],
    components: {HeaderAzul},
    computed : {
        ...mapState(['usuarioChave', 'apiUrl'])
    },
    data: () => ({
        curso : {}
    }),
    methods:{
        getTurma() {
            return axios.post(`${this.apiUrl}turma/get`, {id_turma : parseInt(this.id_turma)}).then( (res) => {
                this.curso = res.data
            })
        },
        init() {
            // Atualiza o curso atual e grava no storage
            const json = localStorage.getItem(this.usuarioChave);
            const sessao = JSON.parse(json);
            sessao['id_curso'] = this.id_curso
            sessao['id_turma'] = this.id_turma
            localStorage.setItem(this.usuarioChave, JSON.stringify(sessao))
            /* eslint-disable no-console */
            // console.log(sessao)
            this.getTurma()
        }
    },
    activated() {
        this.init()
    }
}
</script>

<style scoped>
    h1 {text-align: left; width: 100%; padding-top:50px;  font-weight: lighter; font-size: 40px; color: #FFFFFF; line-height: 100%;}
    h1 strong {font-weight: bold;}
    h2 {font-size: 30px; font-weight: normal; text-align: center; line-height: 110%; color: #00436F;}
    h2 strong {font-weight: bold;}
    .bg {background-image: url("~@/assets/img/bg-tradutor.png"); background-repeat: no-repeat; background-position-x: -50px; background-position-y: -50px;}
</style>